import React from "react"
import { BellhopBell } from "react-blobmoji"

const H1 = props => (
  <h2
    className="text-2xl font-bold my-8 p-4 bg-zinc-100 flex items-start lg:items-center"
    {...props}
  >
    <BellhopBell size={32} className="shrink-0" />
    <span className="ml-2">{props.children}</span>
  </h2>
)
const H2 = props => (
  <h3
    className="text-xl font-bold my-8 py-1 px-4 border-l-8 border-zinc-100"
    {...props}
  >
    {props.children}
  </h3>
)
const H3 = props => (
  <h4 className="text-lg font-bold my-4" {...props}>
    {props.children}
  </h4>
)
const H4 = props => (
  <h5 className="text-base font-bold my-4" {...props}>
    {props.children}
  </h5>
)
const H5 = props => (
  <h6 className="text-base underline my-4" {...props}>
    {props.children}
  </h6>
)
const Paragraph = props => (
  <p
    className="text-sm sm:text-base leading-relaxed sm:leading-relaxed my-4"
    {...props}
  >
    {props.children}
  </p>
)

const Pre = props => (
  <pre className="bg-zinc-100 p-4 overflow-auto my-2 leading-tight text-sm sm:text-base sm:leading-tight">
    {props.children}
  </pre>
)

const InlineCode = props => (
  <code className="text-pink-600">{props.children}</code>
)

const Ul = props => (
  <ul className="text-sm leading-relaxed sm:text-base sm:leading-relaxed list-disc">
    {props.children}
  </ul>
)
const Li = props => <li>{props.children}</li>

const Blockquote = props => (
  <blockquote className="inline-block bg-zinc-100 p-4 my-4">
    {props.children}
  </blockquote>
)
const Td = props => (
  <td className="border p-2" {...props}>
    {props.children}
  </td>
)

const Th = props => (
  <th className="border p-2" {...props}>
    {props.children}
  </th>
)

export const mdxComponents = {
  h1: H1,
  h2: H2,
  h3: H3,
  h4: H4,
  h5: H5,
  p: Paragraph,
  pre: Pre,
  inlineCode: InlineCode,
  ul: Ul,
  li: Li,
  blockquote: Blockquote,
  td: Td,
  th: Th,
}
