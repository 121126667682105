import React from "react"
import { MDXProvider } from "@mdx-js/react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { mdxComponents } from "../components/mdx-components"

const BlogTemplate = ({ data }) => {
  const { mdx } = data
  const { excerpt, frontmatter, body } = mdx
  const { title, createdAt, updatedAt, featuredImage } = frontmatter
  return (
    <Layout>
      <Seo
        title={title}
        description={excerpt}
        image={featuredImage ? featuredImage.publicURL : undefined}
      />
      <MDXProvider components={mdxComponents}>
        <article className="container mx-auto p-4">
          <div className="flex flex-col mb-8">
            <h1 className="text-3xl font-bold">{title}</h1>
            <div className="flex flex-col lg:flex-row text-sm py-1">
              <time className="text-zinc-500" dateTime={createdAt}>
                投稿: {createdAt} ごろ
              </time>
              {updatedAt && updatedAt !== createdAt && (
                <>
                  <span className="mx-2 hidden lg:inline">/</span>
                  <time className="text-zinc-500" dateTime={updatedAt}>
                    更新: {updatedAt} ごろ
                  </time>
                </>
              )}
            </div>
          </div>

          <MDXRenderer>{body}</MDXRenderer>
        </article>
      </MDXProvider>
      <Helmet
        script={[
          {
            type: "application/ld+json",
            innerHTML: JSON.stringify({
              "@context": "http://schema.org",
              "@type": "BlogPosting",
              headline: title,
              datePublished: createdAt,
              dateModified: updatedAt,
              author: {
                "@type": "Person",
                name: "miiton",
                url: "https://miiton.dev",
                sameAs: [
                  "https://github.com/miiton",
                  "https://twitter.com/miiton",
                ],
              },
              image: featuredImage
                ? [`https://miiton.dev${featuredImage.publicURL}`]
                : undefined,
            }),
          },
        ]}
      />
    </Layout>
  )
}

export default BlogTemplate

export const pageQuery = graphql`
  query MDXQuery($id: String!) {
    mdx(id: { eq: $id }) {
      id
      body
      excerpt(truncate: true)
      frontmatter {
        title
        createdAt
        updatedAt
        tags
        featuredImage {
          publicURL
        }
      }
    }
  }
`
